<template>
  <div class="mini-container w100 columnd">
    <h1 class="f22-700 c-lightOrange text-start">Edit Profile</h1>
    <div class="content rowd gap16 w100 stepShowerp">
      <div class="columnd gap24 align-center stepShower">
        <inline-svg
          class="circle-svg"
          :src="require('../../../assets/Icons/circle1.svg')"
          alt=""
        />
        <div class="line"></div>
      </div>
      <div class="columnd gap16 w100">
        <h2 class="f16-700 c-darkBlue text-start fBold">Personal Info</h2>
        <div class="columnd gap16">
          <div class="rowd gap16 w100">
            <div class="upload selectCoin">
              <img src="../../../assets/Background/ValidationProf.svg" alt="" />
            </div>
            <div class="columnd gap16 w100">
              <div class="inputs rowd gap24">
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey">
                    First Name
                  </span>
                  <input class="w100" type="text" />
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Last Name </span>
                  <input class="w100" type="text" />
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Mobile </span>
                  <input class="w100" type="text" />
                </div>
              </div>
              <div class="inputs rowd gap24">
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey">
                    Email Address
                  </span>
                  <input class="w100" type="text" />
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> ID Number </span>
                  <input class="w100" type="text" />
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Language </span>
                  <input class="w100" type="text" />
                </div>
              </div>
              <div class="inputs rowd gap24">
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Instagram </span>
                  <div class="rowd gap8">
                    <inline-svg
                      :src="require('../../../assets/Icons/instagram.svg')"
                    />
                    <input class="w100" type="text" />
                  </div>
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Facebook </span>
                  <div class="rowd gap8">
                    <inline-svg
                      class="fill-darkGrey"
                      :src="require('../../../assets/Icons/facebook.svg')"
                    />
                  </div>
                  <input class="w100" type="text" />
                </div>
                <div class="selectCoin columnd">
                  <span class="f12-700 text-start c-darkGrey"> Twitter </span>
                  <div class="rowd gap8">
                    <inline-svg
                      class="fill-darkGrey"
                      :src="require('../../../assets/Icons/twitter.svg')"
                    />
                    <input class="w100" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">Bio</span>
            <input type="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="content rowd gap16 w100 stepShowerp">
      <div class="columnd gap24 align-center stepShower">
        <inline-svg
          class="circle-svg"
          :src="require('../../../assets/Icons/circle2.svg')"
          alt=""
        />
        <div class="line"></div>
      </div>
      <div class="columnd gap16 w100">
        <h2 class="f16-700 c-darkBlue text-start fBold">Identity Card</h2>
        <div class="uploaddocs rowd space-between gap24">
          <div class="columnd gap24">
            <p class="title c-black f14-400">
              Upload an image of the entire document.
            </p>
            <div class="columnd gap8">
              <div class="rowd align-center gap8">
                <span class="orangeCircle"></span>
                <span class="c-ddGrey f14-400"
                  >It Shuld be in Jpg or Png format</span
                >
              </div>
              <div class="rowd align-center gap8">
                <span class="orangeCircle"></span>
                <span class="c-ddGrey f14-400">In shuld be clear</span>
              </div>
              <div class="rowd align-center gap8">
                <span class="orangeCircle"></span>
                <span class="c-ddGrey f14-400"
                  >It Shuld be in Jpg or Png format</span
                >
              </div>
              <div class="rowd align-center gap8">
                <span class="orangeCircle"></span>
                <span class="c-ddGrey f14-400">In shuld be clear</span>
              </div>
            </div>
          </div>
          <div class="columnd gap4">
            <div class="selectCoin w100 space-between rowd align-center">
              <span class="f14-400 c-darkGrey">ID Type</span>
              <inline-svg
                class="fill-darkGrey"
                :src="require('../../../assets/Icons/arrow.svg')"
              />
            </div>
            <div class="idenupload selectCoin">
              <img
                class="w100 h100"
                src="../../../assets/Background/upload.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content rowd gap16 w100 stepShowerp">
      <div class="columnd gap24 align-center stepShower">
        <inline-svg
          class="circle-svg"
          :src="require('../../../assets/Icons/circle3.svg')"
          alt=""
        />
        <div class="line"></div>
      </div>
      <div class="columnd gap16 w100">
        <h2 class="f16-700 c-darkBlue text-start fBold">Address</h2>
        <div class="rowd gap16">
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">Country</span>
            <input type="text" />
          </div>
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">State</span>
            <input type="text" />
          </div>
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">city</span>
            <input type="text" />
          </div>
        </div>
        <div class="rowd gap16">
          <div style="flex: 1" class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">Postal Code</span>
            <input type="text" />
          </div>
          <div style="flex: 2.1" class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">Addres</span>
            <input type="text" />
          </div>
        </div>
      </div>
    </div>
    <button class="primary-btn f16-700">Submit</button>
  </div>
</template>

<script>
export default {
  name: "Validation",
};
</script>

<style lang="scss" scoped>
.primary-btn {
  max-width: 264px;
  align-self: center;
}
.orangeCircle {
  width: 8px;
  height: 8px;
  background-color: var(--lightOrange);
  border-radius: 50%;
}
.idenupload {
  max-height: 148px !important;
}
.upload {
  max-width: 148px;
  max-height: 148px !important;
}
.selectCoin {
  max-height: 63px;
  flex: 1;
  input {
    background-color: transparent;
  }
}
.circle-svg {
  width: 100px !important;
  height: 100px !important;
  transform: translateY(-20px);
}
.line {
  transform: translateY(-20px);
  width: 1px;
  height: 100%;
  background-color: var(--lightGrey);
}
.mini-container {
  flex-wrap: wrap;
  gap: 56px;
  padding: 32px;
  align-self: flex-start;
}
@media only screen and (max-width: 1100px) {
  .gap16,
  .gap24,
  .gap8 {
    flex-wrap: wrap;
  }
  .stepShower {
    display: none;
  }
  .inputs {
    flex-direction: column !important;
  }
  .uploaddocs {
    width: 100%;
    div {
      width: 100%;
    }
  }
}
</style>
